<!-- oss 文件上传, 使用 v-modell, 返回逗号分隔的 oss 链接
     @author GongLiHai
-->
<template>
  <div>
    <a-upload
      v-model="fileList"
      listType="picture-card"
      :accept="acceptArray"
      :fileList="fileList"
      :remove="removeFile"
      :multiple="true"
      :show-upload-list="true"
      :before-upload="beforeUpload"
      :customRequest="customRequest"
      @preview="handlePreview"
    >
      <div v-if="maxCount === -1 || maxCount > fileList.length">
        <a-icon :type="loading ? 'loading' : 'plus'" />
        <div v-show="!loading" class="ant-upload-text">上传</div>
      </div>
    </a-upload>
    <!-- 预览弹窗 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewCancel" width="80%">
      <span>{{ previewImage }}</span>
      <img v-if="type === 'img'" alt="example" style="width: 100%" :src="previewImage" />
      <video v-if="type === 'video'" ref="previewVideo" style="width: 100%" controls>
        <source :src="previewImage" type="video/mp4" />
      </video>
      <div v-else style="height: 200px; display: flex; justify-content: center; align-items: center;">
        <div>
          文件暂不支持在线预览, 请点击下载至本地后, 进行查看 <br /> <a :href="previewImage">下载文件</a>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { generateFilePath, uploadObject } from '@/api/tool/alioss'

export default {
  props: {
    value: String,
    type: String, // 文件类型, "img" 图片, "video" 视频, null 不限制类型
    // 最大文件大小, 单位 兆M, 默认 20兆
    maxSize: {
      type: Number,
      default: 20
    },
    // 最大数量, 1 限制1张
    maxCount: {
      type: Number,
      default: 1
    }
  },
  created() {
    this.valueChange(this.value);
  },
  data() {
    return {
      acceptArray: '',  // 指定文件类型
      fileList: [], // 文件集合
      loading: false, // 加载状态
      previewVisible: false, // 预览弹窗隐藏/显示
      previewImage: '' // 预览 img url
    }
  },
  methods: {
    // 删除图片
    removeFile(file) {
      this.fileList = this.fileList.filter(item => item.url !== file.url);
      this.$emit('input', this.fileList.map(item => item.url).join(','));
    },
    // 预览按钮点击
    handlePreview(file) {
      this.previewImage = file.url;
      this.previewVisible = true;
    },
    // 上传之前校验
    beforeUpload(file) {
      // 图片校验
      if (this.type === 'img' && !file.type.startsWith('image')) {
        this.$message.error('上传请上传图片文件');
        return false;
      }
      // 视频校验
      if (this.type === 'video' && !file.type.startsWith('video/mp4')) {
        this.$message.error('上传请上传 mp4 视频文件');
        return false;
      }
      // 大小校验
      if (file.size / 1024 / 1024 > this.maxSize) {
        this.$message.error(`上传文件大小不能超过 ${this.maxSize} MB`);
        return false;
      }
    },
    // 自定义上传请求
    customRequest(fileInfo, index) {
      this.loading = true;

      // 文件
      const uploadFile = fileInfo.file;

      // 文件夹目录
      const prefix = 'script'
      const url = this.buildOssUrl(uploadFile.name, prefix);

      // oss 上传
      uploadObject(url, uploadFile).then(res => {
        this.fileList.push(this.createAntFileObj(res))
        this.$message.success('上传成功')
        this.loading = false;
        this.$emit('input', this.fileList.map(item => item.url).join(','));
      })
    },
    // 构建 oss url
    buildOssUrl(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      return generateFilePath(prefix, suffix)
    },
    // 文件随机 uid
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    // 预览关闭
    previewCancel() {
      this.previewVisible = false;
      // 视频暂停播放
      if (this.$refs.previewVideo) {
        this.$refs.previewVideo.pause();
      }
    },
    valueChange(newValue) {
      // 接受上传的文件类型
      if (this.type != null) {
        if (this.type === 'img') {
          this.acceptArray = 'image/*'
        } else if (this.type === 'video') {
          this.acceptArray = 'video/*'
        }
      }

      if (!newValue) {
        this.fileList = [];
        return;
      }
      if (this.maxCount === 1) {
        this.fileList = []
      }
      const imgUrls = newValue.split(',');
      // 判断 url 是否存在, 不存在进行添加
      imgUrls.forEach(imgUrl => {
        for (let i = 0; i < this.fileList.length; i++) {
          const item = this.fileList[i];
          if (item.url == imgUrl) {
            return;
          }
        }
        this.fileList.push(this.createAntFileObj(imgUrl));
      });
    },
    // 创建 ant fileList 的对象
    createAntFileObj(ossUrl) {
      return {
        status: 'done',
        url: ossUrl,
        uid: this.getUidRandom(),
        name: ossUrl,
        thumbUrl: this.getThumbUrl(ossUrl)
      };
    },
    // 预览图片
    getThumbUrl(ossUrl) {
      switch (this.type) {
        // 视频, 使用 oss 封面图功能, 文档: https://help.aliyun.com/zh/oss/user-guide/video-snapshots
        case "video":
          return ossUrl + "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast";
        // 图片/默认
        case "img":
        default:
          return ossUrl;
      }
    }
  },
  watch: {
    // 监听上级 value 改变
    value(newValue) {
      this.valueChange(newValue);
    }
  }
}
</script>